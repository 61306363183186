import React from 'react'
import { Typography } from 'antd'
import { NavLink } from 'react-router-dom'

const { Paragraph } = Typography
const SingleViewCards = () => {

    const carddata = [
        {
            id:1,
            title:'Adenovirus DNA',
            amount:'295',
            desc:'This test detects the presence of adenovirus, a common cause of respiratory infections, conjunctivitis, and gastrointestinal illnesses. Adenovirus can...',
            counter:1
        },
        {
            id:2,
            title:'Adiponectin',
            amount:'150',
            desc:'Adiponectin is a protein hormone secreted by fat cells (adipocytes) that plays a role in regulating glucose levels and fatty acid breakdown.',
            counter:1
        },
        {
            id:3,
            title:'Activated Partial Thromboplastin Time (APTT)',
            amount:'129',
            desc:'The Activated Partial Thromboplastin Time (APTT) test measures how long it takes for your blood to clot. This test evaluates the function…',
            counter:1
        },
        {
            id:4,
            title:'17-Hydroxyprogesterone',
            amount:'129',
            desc:'The 17-Hydroxyprogesterone test measures the levels of a hormone called 17-hydroxyprogesterone in your blood.',
            counter:1
        },
    ]

  return (
    <div className='my-15'>
        <div className='row gap'>
            <div className='col-lg-12'>
                <div>
                    <h2 className='fs-25 text-light-orange fw-400'>You may also like</h2>
                </div>
            </div>
            {
                carddata?.map((data,index)=>
                    <div className='col-lg-3 col-md-6 col-sm-6' key={index}>
                        <div className='card card-az h-100'>
                            <div className='card-header h-100 bg=transparent border-0'>
                                <h6 className='text-white text-center'>
                                    {data?.title}
                                </h6>
                            </div>
                            <div className='card-body'>
                                <div className='cricle-amount'>
                                    <h6 className='text-white text-center m-0'>£ {data?.amount}</h6>
                                </div>
                                <Paragraph
                                    ellipsis={{
                                    rows: 3,
                                        expandable: true,
                                    }}
                                    title={data?.desc}
                                    className='text-white metropill mb-0 fs-14'
                                >
                                    {data?.desc}
                                </Paragraph>
                                <NavLink to={''} className='linkcs metropill'>
                                    Read More
                                </NavLink>
                            </div>
                            <div className='card-footer'>
                                <button className='btn-drop justify-content-center'>
                                    <img src="/assets/icons/drop-b.png" width={30} alt="" />
                                    <span className='metropill'>
                                        {data?.counter} BIOMARKAR ANALYSED
                                    </span>
                                </button>
                                <div className='d-flex justify-content-center pb-4'>
                                    <button className='quick-btn border-0 bg-transparent'>
                                        <span className='metropill'>
                                            QUICK ADD
                                        </span>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                )
            }
        </div>
    </div>
  )
}

export {SingleViewCards}