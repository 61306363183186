import React from 'react'
import './index.css'
import { NewFooter } from '../../components/NewFooter'

const DummyPage = () => {
  return (
    <div>
        <div className='breadcrumnb-img-bg '>
          <div className='container-fluid mx-auto navbar-width'>
            {/* <NewNavbar /> */}
            {/* <BreadCrumbsNew /> */}
          </div>
        </div>
        <p>
            Lorem ipsum dolor sit amet, consectetur adipisicing elit. In dolore quam deserunt temporibus, nemo vero nisi et delectus porro! Ullam, architecto at! Esse consequuntur veritatis fugit asperiores reprehenderit, quam iusto? Lorem ipsum dolor sit amet, consectetur adipisicing elit. In dolore quam deserunt temporibus, nemo vero nisi et delectus porro! Ullam, architecto at! Esse consequuntur veritatis fugit asperiores reprehenderit, quam iusto? Lorem ipsum dolor sit amet, consectetur adipisicing elit. In dolore quam deserunt temporibus, nemo vero nisi et delectus porro! Ullam, architecto at! Esse consequuntur veritatis fugit asperiores reprehenderit, quam iusto?
            Lorem ipsum dolor sit amet, consectetur adipisicing elit. In dolore quam deserunt temporibus, nemo vero nisi et delectus porro! Ullam, architecto at! Esse consequuntur veritatis fugit asperiores reprehenderit, quam iusto?
            Lorem ipsum dolor sit amet, consectetur adipisicing elit. In dolore quam deserunt temporibus, nemo vero nisi et delectus porro! Ullam, architecto at! Esse consequuntur veritatis fugit asperiores reprehenderit, quam iusto?
            Lorem ipsum dolor sit amet, consectetur adipisicing elit. In dolore quam deserunt temporibus, nemo vero nisi et delectus porro! Ullam, architecto at! Esse consequuntur veritatis fugit asperiores reprehenderit, quam iusto?
            Lorem ipsum dolor sit amet, consectetur adipisicing elit. In dolore quam deserunt temporibus, nemo vero nisi et delectus porro! Ullam, architecto at! Esse consequuntur veritatis fugit asperiores reprehenderit, quam iusto?
            Lorem ipsum dolor sit amet, consectetur adipisicing elit. In dolore quam deserunt temporibus, nemo vero nisi et delectus porro! Ullam, architecto at! Esse consequuntur veritatis fugit asperiores reprehenderit, quam iusto?
            Lorem ipsum dolor sit amet, consectetur adipisicing elit. In dolore quam deserunt temporibus, nemo vero nisi et delectus porro! Ullam, architecto at! Esse consequuntur veritatis fugit asperiores reprehenderit, quam iusto?
            Lorem ipsum dolor sit amet, consectetur adipisicing elit. In dolore quam deserunt temporibus, nemo vero nisi et delectus porro! Ullam, architecto at! Esse consequuntur veritatis fugit asperiores reprehenderit, quam iusto?

            Lorem ipsum dolor sit amet, consectetur adipisicing elit. In dolore quam deserunt temporibus, nemo vero nisi et delectus porro! Ullam, architecto at! Esse consequuntur veritatis fugit asperiores reprehenderit, quam iusto?
            Lorem ipsum dolor sit amet, consectetur adipisicing elit. In dolore quam deserunt temporibus, nemo vero nisi et delectus porro! Ullam, architecto at! Esse consequuntur veritatis fugit asperiores reprehenderit, quam iusto?
            Lorem ipsum dolor sit amet, consectetur adipisicing elit. In dolore quam deserunt temporibus, nemo vero nisi et delectus porro! Ullam, architecto at! Esse consequuntur veritatis fugit asperiores reprehenderit, quam iusto?

        </p>
        <NewFooter />
    </div>
  )
}

export default DummyPage