import React from 'react'
import { NewNavbar, BreadCrumbsNew, HealthConcernCards, NewFooter, SendMessageForm } from '../../components';

const HealthConcernPage = () => {
  const breadcrumbs = [
    { label: 'Home', path: '/' },
    { label: 'Blood Testing', path: '/blood-testing' },
    { label: 'Health Concern' },
  ];

  
  return (
    <div>
        <div className='breadcrumnb-img-bg'>
          <div className='container-fluid mx-auto navbar-width'>
            <NewNavbar />
            <BreadCrumbsNew breadcrumbs={breadcrumbs} title={'Health Concern'} />
          </div>
        </div>
        <div className='container-fluid my-5 mx-auto navbar-width'>
          <HealthConcernCards />
          <SendMessageForm />
        </div>
        <NewFooter />
    </div>
  )
}

export {HealthConcernPage}