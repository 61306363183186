import React from 'react'
import { Collapse, Drawer, Flex, Typography } from 'antd'

const { Title } = Typography
const { Panel } = Collapse
const DrawerBiomarker = ({visible,onClose,biomarkerData}) => {

    const sortedBiomarkers = biomarkerData ? 
    [...biomarkerData].sort((a, b) => {
      const nameA = (a.name || a.biomaker || '').toLowerCase();
      const nameB = (b.name || b.biomaker || '').toLowerCase();
      return nameA.localeCompare(nameB);
    }) 
    : [];

  return (
    <Drawer
        title={null}
        onClose={onClose}
        className="drawer"
        open={visible}
        width={400}
        footer={null}
    >
        <Flex align='center' justify='space-between'>
            <div className='pad-title'>
                <Title level={3} className='m-0 '>
                    Biomarkers
                </Title>
            </div>
            <button type='button' className='btnclose-drawer' onClick={onClose}>
                <img src="/assets/icons/close.png" width={15} alt="" />
            </button>
        </Flex>
        <div className='content-draw'>
            
            {
                sortedBiomarkers.length > 0 ? (
                    <Collapse accordion className="custom-collapse">
                        {
                            sortedBiomarkers.map((biomarker, index) => (
                            <Panel 
                                className='custom-panel' 
                                header={<span className=''>{biomarker.name || biomarker.biomaker}</span>}
                                key={index}
                                >
                                <p className='metrophofont'>{biomarker.description}</p>
                            </Panel>
                        ))}
                    </Collapse>
                )
                :
                (
                    <p>No details available.</p>
                )
            }
            {/* <div class="accordion accordion-flush" id="accordionFlushExample">
            {sortedBiomarkers.length > 0 ? (
                sortedBiomarkers.map((biomarker, index) => (
                <div class="accordion-item">
                    <h2 class="accordion-header" id={`flush-heading${index}`}>
                        <button class="accordion-button metrophofont collapsed px-0" type="button" data-bs-toggle="collapse" data-bs-target={`#flush-collapse${index}`} aria-expanded="false" aria-controls="flush-collapseOne">
                            {biomarker.name || biomarker.biomaker}
                        </button>
                    </h2>
                    <div id={`flush-collapse${index}`} class="accordion-collapse collapse" aria-labelledby="flush-headingOne" data-bs-parent="#accordionFlushExample">
                        <div class="accordion-body">
                            <p className='metrophofont text-justify'>
                                {biomarker.description}
                            </p>
                        </div>
                    </div>
                </div>
                ))
            ):
            (
                <p>No details available.</p>
              )}
            </div> */}
            
        </div>
    </Drawer>
  )
}

export {DrawerBiomarker}