import React, { useState, useEffect } from "react";
import { useParams} from 'react-router-dom';


import Navbar from "../components/Navbar/index.jsx";
import { BreadCrumb } from "../components/Navbar/BreadCrumb.js";
import PageLoader from "../components/PageLoader";
import "./CheckoutStyle.css";
import { get, post } from '../services/ApiService.js';

const AppointmentSuccess = () => {
const { bookingId, userToken } = useParams();
const [isLoading, setIsLoading] = useState(true);
const [isError, setError] = useState(false);
const [appData, setAppData] = useState('');

useEffect(() => {
    if (bookingId && userToken) {
    
        PaymentConfirmation();
    } else {
        setError(true);
        setIsLoading(false);
    }
}, [bookingId, userToken]);

const PaymentConfirmation = async () => {
    setIsLoading(true)
    try {
        const Body = {
            bookingId,
            userToken
        };
        setIsLoading(true);
        const res = await post(`api/booking/payment/confirmation`, Body);
        console.log(res);
        if (res?.success) {
           setIsLoading(false)
           setAppData(res.data)
        } else {
            setIsLoading(false);
            setError(true)
        }
    } catch (error) {
        setError(true)
        setIsLoading(false);
        console.error(error);
    }
};
  return (
    <>
      <main id="content" className="wrapper layout-page">
        {/* <div className='bg-banner'> */}
            <Navbar  />
            <BreadCrumb title="Payment Success" pageName="Payment success" bgImg={`${process.env.REACT_APP_BASE_URL}/assets/images/banner/banner-sample.jpg`} />
        {/* </div> */}
      {isLoading && <PageLoader />} 
      <section className="container pb-14 pb-lg-19" style={{marginTop:"100px"}}>

        <div className="row">
            <div className="col-lg-1 col-md-1 col-sm-12"></div>
            <div className="col-lg-6 col-md-6 col-sm-12">
                <h3 className="theme-font mt-5">Appointment Confirmed Successfully</h3>
                <p className="mt-4 p-0 app-detail">Thank you for booking with us.</p>
                <p className="m-0 p-0 app-detail">Your appointment is scheduled now, if there is anything you need before your arrival, please don't hesitate to reach out us.</p>
                <p className="m-0 p-0 app-detail">We look forward to seeing you!</p>
                <button className="btncust mt-5 theme-font">Explore More</button>
            </div>
            <div className="col-lg-4 col-md-4 col-sm-12 text-left">
                <div className="card shadow app-card">
                    <div className="card-header app-header theme-font">
                        <h6>Appointment Details</h6>
                    </div>
                    <div className="card-body text-center">
                       <table className="table ">
                        <tbody>
                            <tr>
                                <td style={{textAlign:'left'}}>
                                   <span className="app-title theme-font">Provider</span> 
                                </td>
                                <td style={{textAlign:'right'}}>
                                   <span className="app-desc ">My Private Chemist</span> 
                                </td>
                            </tr>
                            <tr>
                                <td style={{textAlign:'left'}}>
                                   <span className="app-title theme-font">Date</span> 
                                </td>
                                <td style={{textAlign:'right'}}>
                                   <span className="app-desc">{appData?.dateTime}</span> 
                                </td>
                            </tr>
                            <tr>
                                <td style={{textAlign:'left'}}>
                                   <span className="app-title theme-font">Payment Status</span> 
                                </td>
                                <td style={{textAlign:'right'}}>
                                   <span className="app-desc"><i className="fa fa-check"></i> Success</span> 
                                </td>
                            </tr>
                        </tbody>
                       </table>
                    </div>
                </div>
            </div>
            <div className="col-lg-1 col-md-1 col-sm-12"></div>
        </div>
      </section>

      </main>
    </>
  )
}

export default AppointmentSuccess