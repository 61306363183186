import React, { useState, useEffect, useRef } from "react";
import {useNavigate } from 'react-router-dom';
import Navbar from "./Navbar/index.jsx";
import { get, post } from '../../services/ApiService.js';
import  './index.css'
import { CardsComponent } from "./Card/index.jsx";
import { Sidebar } from "./Sidebar/index.jsx";
import { Suggestion } from "./Suggestion/index.jsx";
import { MobileFilter } from "./Navbar/MobileFilter/index.jsx";
import toast, { Toaster } from 'react-hot-toast';
import { notification } from 'antd';
import { BreadCrumbsNew, NewFooter, NewNavbar } from "../../components/index.jsx";
import DataDisplay from "./DataDisplay.jsx";


const BuildMyPkg = () => {

    const navigate = useNavigate();
    const [selectedServiceType, setSelectedServiceType] = useState('all');
    const [isLoading, setIsLoading] = useState(false);
    const [isDataLoading, setIsDataLoading] = useState(true);
    const [isLoad, setIsLoad] = useState(true);
    const [isNotFound, setNotFound] = useState(false); 
    const [data, setData] = useState([]); 
    const [searchValue, setSearchValue] = useState('');
    const [selectedItems, setSelectedItems] = useState([]);
    const [biomarkerData, setBiomarkers] = useState([]);
    const [selectedBiomarkers, setSelectedBiomarkers] = useState([]);
    const [showModal, setShowModal] = useState(false);
    const [totalPackages, setTotalPackages] = useState([]);
    const [totalBiomarkers, setTotalBiomarkers] = useState([]);
    const [isRecommending, setIsRecommending]= useState(false);
    const [userToken, setUserToken] = useState('');
    const [cart, setCart] = useState('');
    const [isAddingCart, setIsAddingCart]= useState(false);
    const [isRequiresManualBooking, setRequiresManualBooking]= useState(false);
    const [confirmLoading, setConfirmLoading] = useState(false);
    const [isMailSent, setIsMailSent] = useState(false);

    const suggestionRef = useRef(null);

    const handleTypeChange = (value) => {
        setSelectedServiceType(value);
        setIsDataLoading(true);
    };
    


    useEffect(() => {
      const loadData = async () => {
          setIsLoad(true);
          try {
              await fetchData();
              await fetchBiomarkers();
          } catch (error) {
              console.error("Error fetching data:", error);
          } finally {
              setIsLoad(false);
          }
      };
  
      if (selectedServiceType && selectedServiceType !== '') {
          loadData();
      }
  }, [selectedServiceType]);
  

    useEffect(() => {
      setSelectedServiceType('all');
        fetchData();
    }, [searchValue]);



    const fetchBiomarkers = async () => {
        try {
          const res = await get('api/package/biomarkers');
          if (res.success) {
            setBiomarkers(res.biomarkers);
          } else {
            setBiomarkers([]);
          }

        } catch (error) {
            setBiomarkers([]);
        }
    };

    const fetchData = async () => {
        try {
          setIsRecommending(false);
          setIsDataLoading(true);
          setNotFound(false);
          const res = await post('api/package/data', { type:selectedServiceType, search:searchValue });
          if (res.success) {
            setData(res.data);
          } else {
            setData([]);
            setNotFound(true);
          }
          setIsDataLoading(false);
        } catch (error) {
          setData([]);
          setNotFound(true);
          setIsDataLoading(false);
        }
    };

    const handleAdd = (id, type) => {
        const item = data.find(item => item._id === id);
        if (item) {
          const newItem = {
            id: item._id,
            name: type === 'bio' ? item.biomaker : item.name,
            type,
            price: item.price
          };
          setSelectedItems([...selectedItems, newItem]);
        }
    };

    const handleRemove = (id) => {
        setSelectedItems(selectedItems.filter(item => item.id !== id));
        setIsRecommending(false);
    };

    const isItemSelected = (id) => {
        return selectedItems.some(item => item.id === id);
    };

    const truncateText = (text, wordLimit)=> {
        if (typeof text !== 'string') return '';
    
    
        if (text.length > wordLimit) {
            return text.slice(0, wordLimit) + '...';
        }
        return text;
    };

    const buildPkgRecomendation = async () => {
        try {
          setIsLoading(true);
          setIsRecommending(false)
          setIsMailSent(false);
          setTotalPackages([]);
          setTotalBiomarkers([]);
          const pack = selectedItems.filter(item => item.type === 'pro').map(item => item.id);
          const bio = selectedItems.filter(item => item.type === 'bio').map(item => item.id);
          const res = await post('api/package/build-package', {packages:pack, biomarkers:bio,userToken});
          setIsRecommending(true)
          console.log(res)
          if (res.success) {
            setTotalPackages(res?.package);
            setTotalBiomarkers(res?.biomaker);
            setCart(res.cart);
            setRequiresManualBooking(res.requiresManualBooking);
            window.scrollTo({
              top: 500,
              behavior: 'smooth', // Optional: Smooth scrolling
            });


          } else {
            setTotalPackages([]);
            setTotalBiomarkers([]);
          }
          setIsLoading(false);
        } catch (error) {
            setTotalPackages([]);
            setTotalBiomarkers([]);
          setIsLoading(false);
        }
    };


    const closeModal = () => {
        setShowModal(false);
    };

    const AddingToCart = async () => {

      setIsAddingCart(true)
        try {
            const Body = {
                profiles:totalPackages,
                biomarkers:totalBiomarkers
            };
            setIsAddingCart(true);
            const res = await post(`api/cart/bulk/add-to-cart`, Body);
            console.log(res);
            if (res?.success) {
              setIsAddingCart(false)
              navigate('/cart');
            } else {
              setIsAddingCart(false);
            }
        } catch (error) {
            setIsAddingCart(false);
            console.error(error);
        }
    };

    const ManualBooking = async (values) => {
      setConfirmLoading(true);
        try {
            const Body = {
                profiles:totalPackages,
                biomarkers:totalBiomarkers,
                name:values.name,
                email:values.email,
                phone:values.phone,
                bookingDate:values.bookingDate,
                bookingTime:values.bookingTime,
                additionalInfo:values.additionalInfo
            };
            const res = await post(`api/cart/manual/booking`, Body);
            setConfirmLoading(false);

            if (res?.success) {
              setIsMailSent(true);
              notification.success({
                  message: 'Success',
                  description: 'Your email was sent successfully! Our team will get back to you soon.',
                  duration: 4, 
                  placement: 'topRight',
              });

            } else {

            }
        } catch (error) {
            setConfirmLoading(true);
            console.error(error);
        }
    };

    const breadcrumbs = [
      { label: 'Home', path: 'https://www.beta.myprivatechemist.com/' },
      { label: 'Blood Testing', path: 'https://www.beta.myprivatechemist.com/blood-testing/' },
      { label: 'Build My Page' },
    ];

  return (
    <>
      <Toaster 
        position="top-right" 
        reverseOrder={false} 
        toastOptions={{
          duration: 3000, // Minimum duration for the toast
        }}
        className="metrolpill"
      />
      <div>
        <div className='breadcrumnb-img-bg'>
          <div className='container'>
            <NewNavbar />
            <BreadCrumbsNew breadcrumbs={breadcrumbs} title={'Build My Package'} />
          </div>
        </div>
        <div className='container my-5'>
          <div className='row g-3'>
            <div className='col-lg-12'>
              <CardsComponent 
                selectedServiceType={selectedServiceType} 
                setSearchValue={setSearchValue}  
                searchValue ={searchValue} 
                handleTypeChange={handleTypeChange} 
                isRecommending={isRecommending}
              />
              <div className="row">
                <div className="col-lg-9">
                  {
                    !isRecommending && 
                    <DataDisplay 
                      isLoad={isLoad}
                      isNotFound={isNotFound}
                      data={data}
                      isItemSelected={isItemSelected} 
                      biomarkerData={biomarkerData}
                      handleAdd={handleAdd} 
                      handleRemove={handleRemove}
                      selectedServiceType={selectedServiceType}  
                    />
                  }
                  {isRecommending && 
                    <Suggestion data={totalPackages} biomaker={totalBiomarkers} selectedItems = {selectedItems} resultRef={suggestionRef} cart={cart} AddingToCart={AddingToCart} isAddingCart={isAddingCart} isRequiresManualBooking={isRequiresManualBooking} ManualBooking={ManualBooking} confirmLoading={confirmLoading} isMailSent={isMailSent} setIsMailSent={setIsMailSent}/>
                  }
                </div>
                <div className="col-lg-3 pe-xl-0">
                  <Sidebar 
                    selectedItems ={selectedItems} 
                    buildPkgRecomendation={buildPkgRecomendation} 
                    isLoading = {isLoading} 
                    isRecommending={isRecommending}  
                    setIsRecommending={setIsRecommending}
                    handleRemove={handleRemove}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div>
        <MobileFilter            
          selectedItems ={selectedItems} 
          buildPkgRecomendation={buildPkgRecomendation} 
          isLoading = {isLoading} 
          isRecommending={isRecommending}  
          setIsRecommending={setIsRecommending}
          handleRemove={handleRemove}
        />
        </div>
        <NewFooter />
    </div>
    {/* <div className='d-flex flex-lg-row flex-column'>
        
        <div className='w-80s'>
            <div className='bg-banner'>
                <Navbar setSearchValue={setSearchValue} searchValue ={searchValue}  />
                <div className="ps-5">
                  <BreadCrumbsNew breadcrumbs={breadcrumbs} title={'Build My Package'} />
                </div>
            </div>
            <div className='container-fluid mbs-100'>
              
            </div>
        </div>
        
    </div> */}
        {showModal && (
            <div className="modal-overlay" onClick={closeModal}>
            <div className="modal-content modal-md" onClick={(e) => e.stopPropagation()}>
                <div className="modal-header">
                    <h5 className="modal-title">Biomarkers List included</h5>
                <button type="button" className="btn-close" onClick={closeModal}>×</button>
                </div>
                <div className="modal-body">
                <div className="scrolable-row">
                {selectedBiomarkers.length > 0 ? (
                    <ol className="gradient-list">
                    {selectedBiomarkers.map((biomarker, index) => (
                        <li key={index}>{biomarker.biomaker}</li>
                    ))}
                    </ol>
                ) : (
                    <p>No biomarkers available.</p>
                )}
                </div>

                </div>
            </div>
            </div>
        )}

    </>
  )
}

export default BuildMyPkg