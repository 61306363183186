import { Collapse, Typography } from 'antd'
import React from 'react'

const { Panel } = Collapse
const { Text } = Typography
const SingleViewTabs = ({data}) => {
  return (
    <div className='my-15'>
        <nav>
          <div className="nav nav-tabs nav-tabs-cust" id="nav-tab" role="tablist">
            <button className="nav-link active metropill-thin" id="nav-home-tab" data-bs-toggle="tab" data-bs-target="#nav-home" type="button" role="tab" aria-controls="nav-home" aria-selected="true">DESCRIPTION</button>
            <button className="nav-link metropill-thin" id="nav-profile-tab" data-bs-toggle="tab" data-bs-target="#nav-profile" type="button" role="tab" aria-controls="nav-profile" aria-selected="false">BIO MARKER</button>
            <button className="nav-link metropill-thin" id="nav-contact-tab" data-bs-toggle="tab" data-bs-target="#nav-contact" type="button" role="tab" aria-controls="nav-contact" aria-selected="false">RESULTS</button>
          </div>
        </nav>
        <div className="tab-content tab-cont" id="nav-tabContent">
          <div className="tab-pane fade show active" id="nav-home" role="tabpanel" aria-labelledby="nav-home-tab" tabindex="0">
            <h2 className='fs-56 font-400-weight mb-10'>Description</h2>
            <p className='metropill-thin text-black'>
              {data?.description}
            </p>
          </div>
          <div className="tab-pane fade" id="nav-profile" role="tabpanel" aria-labelledby="nav-profile-tab" tabindex="0">
            <h2 className='fs-56 font-400-weight mb-10'>Biomarkers</h2>
            {
              data?.type === 'pro' ?
              <Collapse className="custom-collapse collapse-single">
                {
                    data?.biomarkers.map((bio,index)=> (
                    <Panel 
                        className='custom-panel' 
                        header={<span className='metropill-thin fs-18'>{bio.name || bio.biomaker}</span>}
                        key={index}
                        >
                        <p className='metropill-thin fs-18'>{bio.description}</p>
                    </Panel>
                ))}
              </Collapse>
              :
              <Text className='metropill-thin font-span text-black'>
                No biomarker found
              </Text>
              // <ul>
              //   {
              //     data?.biomarkers.map((bio,index)=>
              //       <li key={index}>
              //         <Text className='metropill-thin font-span text-black'>
              //             {
              //               bio?.biomaker
              //             }
              //         </Text>
              //       </li>
              //     )
              //   }
              // </ul>
              // :
              // <ul>
              //   <li>
              //     <Text className='metrophofont font-span text-black'>
              //       {data?.name}
              //     </Text>
              //   </li>
              // </ul>
            }
          </div>
          <div className="tab-pane fade" id="nav-contact" role="tabpanel" aria-labelledby="nav-contact-tab" tabindex="0">
            <h2 className='fs-56 font-400-weight mb-10'>Results</h2>
            <p className='metropill-thin font-span text-black'>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut elit tellus, luctus nec ullamcorper mattis, pulvinar dapibus leo.
            </p>
          </div>
        </div>
    </div>
  )
}

export {SingleViewTabs}