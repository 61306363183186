import React from 'react'
import './footer.css'
import { Link } from 'react-router-dom'
const Footer = () => {
  return (
    <>
    <footer className="pt-15 pt-lg-20 pb-16 footer bg-section-4">
      <div className="container container-xxl pt-4" style={{paddingLeft:'100px',paddingRight:'100px'}}>
        <div className="row">
          <div className="col-lg-3 col-md-3 col-12 mb-11 mb-lg-0 fs-14px">
            <h3 className="mb-6 metropill footer-heading">Contact</h3>
            <ul className="list-unstyled mb-0 fw-medium">
              <li className="list-item">
                <span className='list-icon'>
                  <i className='fas fa-map-marker-alt'></i>
                </span>
                <span className='list-text metropill'>Longcraft House, 2-8 victoria Avenue London, EC2M 4NS</span>
              </li>
              <li className="list-item">
                <span className='list-icon'>
                  <i className='far fa-envelope'></i>
                </span>
                <span className='list-text metropill'>info@myprivatechemist.com</span>
              </li>
              <li className="list-item">
                <span className='list-icon'>
                  <i className='fas fa-phone-alt'></i>
                </span>
                <span className='list-text metropill'>0203 987 920</span>
              </li>
            </ul>
          </div>
          <div className="col-lg-6 col-md-6 col-12 mb-11 mb-lg-0">
            <h3 className=" mb-6 footer-heading metropill text-center">Quick Links</h3>
            <div className='row'>
              <div className='col-lg-6 col-md-6 col-sm-12 col-12'>
                <ul className="list-unstyled mb-0 fw-medium">
                  <li className="list-item">
                    <Link to='https://www.beta.myprivatechemist.com/blood-testing' className='list-link'>
                    <span className='list-icon'>
                      <i className='fas fa-plus'></i>
                    </span>
                    <span className='list-text metropill'>Blood Testing</span>
                    </Link>
                  </li>
                  <li className="list-item">
                  <Link to='https://www.beta.myprivatechemist.com/travel-clinic/' className='list-link'>

                    <span className='list-icon'>
                      <i className='fas fa-plus'></i>
                    </span>
                    <span className='list-text metropill'>Travel Clinic</span>
                    </Link>
                  </li>
                  <li className="list-item">
                  <Link to='https://www.beta.myprivatechemist.com/treatment-medication' className='list-link'>

                    <span className='list-icon'>
                      <i className='fas fa-plus'></i>
                    </span>
                    <span className='list-text metropill'>Treatment & Medication</span>
                    </Link>
                  </li>
                  <li className="list-item">
                  <Link to='/https://www.beta.myprivatechemist.com' className='list-link'>

                    <span className='list-icon'>
                      <i className='fas fa-plus'></i>
                    </span>
                    <span className='list-text metropill'>Consultation</span>
                    </Link>
                  </li>
                  <li className="list-item">
                  <Link to='/https://www.beta.myprivatechemist.com' className='list-link'>

                    <span className='list-icon'>
                      <i className='fas fa-plus'></i>
                    </span>
                    <span className='list-text metropill'>GP Services</span>
                    </Link>
                  </li>
                </ul>
              </div>
              <div className='col-lg-6 col-md-6 col-sm-12 col-12'>
                <ul className="list-unstyled mb-0 fw-medium">
                  <li className="list-item">
                    <Link to='/https://www.beta.myprivatechemist.com' className='list-link'>
                    <span className='list-icon'>
                      <i className='fas fa-plus'></i>
                    </span>
                    <span className='list-text metropill'>Health Checks</span>
                    </Link>
                  </li>
                  <li className="list-item">
                    <Link to='/https://www.beta.myprivatechemist.com' className='list-link'>
                    <span className='list-icon'>
                      <i className='fas fa-plus'></i>
                    </span>
                    <span className='list-text metropill'>Corporate</span>
                    </Link>
                  </li>
                  <li className="list-item">
                    <Link to='/https://www.beta.myprivatechemist.com' className='list-link'>
                    <span className='list-icon'>
                      <i className='fas fa-plus'></i>
                    </span>
                    <span className='list-text metropill'>Sexual Health</span>
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className="col-lg-3 col-md-6 col-12 mb-11 mb-lg-0">
            <h3 className="mb-6 footer-heading metropill">Opening</h3>
              <ul className="list-unstyled mb-0 fw-medium">
                  <li className="list-item">
                    <span className='list-text metropill'>Monday 09:00-1800</span>
                  </li>
                  <li className="list-item">
                    <span className='list-text metropill'>Tuesday 09:00-1800</span>
                  </li>
                  <li className="list-item">
                    <span className='list-text metropill'>Wednesday 09:00-1800</span>
                  </li>
                  <li className="list-item">
                    <span className='list-text metropill'>Thursday 09:00-1800</span>
                  </li>
                  <li className="list-item">
                    <span className='list-text metropill'>Friday 09:00-1800</span>
                  </li>
                  <li className="list-item">
                    <span className='list-text metropill'>Saturday Closed</span>
                  </li>
                  <li className="list-item">
                    <span className='list-text metropill'>Sunday Closed</span>
                  </li>
              </ul>
          </div>
        </div>
        <div className='row'>
          <div className='col-lg-3 col-md-3 col-12 mb-11 mb-lg-0'>
            <p className='heading-title metropill'>
              <b className='metropill'>Company</b>: MI Health Ltd
            </p>
            <p className='heading-title metropill'>
              <b className='metropill'>Premisess GPhC Number</b> : 9011548
            </p>
            <p className='heading-title metropill'>
              <b className='metropill'>Superintendent</b> : Imran Tailor (221614)
            </p>
            <img src='/assets/images/pharma-council.png' alt='' />
          </div>
          <div className='col-lg-6 col-md-6 col-12 mb-11 mb-lg-0 text-center'>
            <img src='/logo.png' alt='' />
          </div>
          <div className='col-lg-3 col-md-3 col-12 mb-11 mb-lg-0'>
            <p className='heading-title2 metropill'>Follow Us or Contact</p>
            <div className='d-inline-block'>
              <img src='/assets/images/fb.png' className='social-icon' alt=''/>
              <img src='/assets/images/twitter.png' className='social-icon' alt=''/>
              <img src='/assets/images/insta.png' className='social-icon' alt=''/>
            </div>
            <p className='heading-title2 metropill'>Payment Accepted</p>
              <ul className="list-unstyled mb-0 inline-items-list">
                  <li className="list-item">
                    <Link to='/https://www.beta.myprivatechemist.com' className='list-link'>
                    <span className='list-icon' style={{fontSize:'25px'}}>
                      <i className='fab fa-cc-visa'></i>
                    </span>
                    </Link>
                  </li>
                  <li className="list-item">
                    <Link to='/https://www.beta.myprivatechemist.com' className='list-link'>
                    <span className='list-icon' style={{fontSize:'25px',marginLeft:'10px'}}>
                      <i className='far fa-credit-card'></i>
                    </span>
                    </Link>
                  </li>
              </ul>

          </div>


        </div>
 
      </div>
    </footer>

    </>
  )
}

export default Footer