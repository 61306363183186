import React from 'react'
import './Loader.css';

const PageLoader = () => (
    <>
    <div class="overlayeffect">
		<div class="spinner-border text-warning" role="status">
		  <span class="visually-hidden">Loading...</span>
		</div>
	</div>
    </>

);

export default PageLoader;
