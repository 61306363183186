import React from 'react'
import { NavLink } from 'react-router-dom'
import './index.css'

const NewFooter = () => {

    const data = [
        {
            title:'Contact',
            id: 1,
            content:[
                {
                    icon:"map.png",
                    desc:"Longcraft House, 2-8 victoria Avenue London, EC2M 4NS"
                },
                {
                    icon:"email.png",
                    desc:"info@myprivatechemist.com"
                },
                {
                    icon:"call.png",
                    desc:"0203 987 920"
                },
            ]
        },
        {
            title:'Quick Links',
            id:2,
            content:[
                {
                    path:'',
                    desc:"Home"
                },
                {
                    path:'',
                    desc:"Our Mission"
                },
                {
                    path:'',
                    desc:"Contact"
                },
                {
                    path:'',
                    desc:"Blood Testing"
                },
                {
                    path:'',
                    desc:"Travel Clinic"
                },
                {
                    path:'',
                    desc:"Treatment & Medication"
                },
                {
                    path:'',
                    desc:"Consultation"
                },
                {
                    path:'',
                    desc:"GP Services"
                },
                {
                    path:'',
                    desc:"Health Checks"
                },
                {
                    path:'',
                    desc:"Corporate"
                },
                {
                    path:'',
                    desc:"Sexual Health"
                },
            ]
        },
        {
            title:'Opening',
            id:3,
            content:[
                {
                    desc:"Monday 09:00-1800"
                },
                {
                    desc:"Tuesday 09:00-1800"
                },
                {
                    desc:"Wednesday 09:00-1800"
                },
                {
                    desc:"Thursday 09:00-1800"
                },
                {
                    desc:"Friday 09:00-1800"
                },
                {
                    desc:"Saturday Closed"
                },
                {
                    desc:"Sunday Closed"
                },
            ]
        },
    ]

    const socialdata = [
        {
            title:'Follow Us or Contact',
            content:[
                {
                    path:'',
                    img:'fb.png'
                },
                {
                    path:'',
                    img:'tw.png'
                },
                {
                    path:'',
                    img:'inst.png'
                },
            ]
        },
        {
            title:'Payment Accepted',
            id: 2,
            content:[
                {
                    path:'',
                    img:'visa.png'
                },
                {
                    path:'',
                    img:'card.png'
                },
            ]
        },

    ]
  return (
    <div className='footer-new'>
        <div className='container'>
            <div className='row g-3'>
                {
                    data?.map((data, index)=>
                        <div className={data?.id === 2 ? 'col-xl-6 col-lg-4' : 'col-xl-3 col-lg-4'} key={index}>
                            <div className={
                                data?.id === 2 ? 'mar-list' : null
                            }>
                                <h4 className='fw-bold title metropill'>{data?.title}</h4>
                                {
                                data.id === 1 ? (
                                    data?.content?.map((cont, i) => (
                                    <div className="d-flex gap-3 align-items-start mb-3" key={i}>
                                        <div className="flex-shrink-0 d-flex pt-3">
                                        <img
                                            src={`/assets/icons/${cont?.icon}`}
                                            width={12}
                                            alt=""
                                            className="img-fluid"
                                        />
                                        </div>
                                        <p className="m-0 metropill desc">{cont?.desc}</p>
                                    </div>
                                    ))
                                ) 
                                : 
                                data.id === 2 ? (
                                    <div className="list">
                                        <ul>
                                            {data?.content?.map((cont, i) => (
                                            <li key={i}>
                                                <NavLink to={''}>
                                                    <div className="d-flex gap-3 align-items-start mb-3">
                                                        <div className="flex-shrink-0 d-flex pt-3">
                                                        <img
                                                            src="/assets/icons/plus.png"
                                                            width={12}
                                                            alt=""
                                                            className="img-fluid"
                                                        />
                                                        </div>
                                                        <p className="m-0 metropill desc">{cont?.desc}</p>
                                                    </div>
                                                </NavLink>
                                            </li>
                                            ))}
                                        </ul>
                                    </div>
                                ) : (
                                    <div>
                                        {data?.content?.map((cont, i) => (
                                            <p className="m-0 metropill desc">{cont?.desc}</p>
                                        ))}
                                    </div>
                                )
                            }
                            </div>
                        </div>
                    )
                }
                
            </div>
            <div className='row mt-5 pt-5'>
                <div className='col-lg-4 col-xl-3'>
                    <div>
                        <p className='metropill fs-13 mb-3'>
                            <strong className='metropill fw-bold'>Company: </strong>
                            MI Health Ltd
                        </p>
                        <p className='metropill fs-13 mb-3'>
                            <strong className='metropill fw-bold'>Premisess GPhC Number: </strong>
                            9011548
                        </p>
                        <p className='metropill fs-13 mb-3'>
                            <strong className='metropill fw-bold'>Superintendent: </strong>
                            Imran Tailor (221614)
                        </p>
                        <img src="/assets/icons/gen.png" width={146} className='img-fluid' alt="" />
                    </div>
                </div>
                <div className='col-lg-4 col-xl-6'>
                    <div className='d-flex justify-content-center my-5'>
                        <NavLink to={''}>
                            <img src="/logo.png" className='img-fluid' width={180} height={87} alt="" />
                        </NavLink>
                    </div>
                </div>
                <div className='col-lg-4 col-xl-3'>
                    {
                        socialdata?.map((social,i)=>
                        <div className='mb-5' key={i}>
                            <h4 className='fw-bold mb-5 title metropill'>
                                {social?.title}
                            </h4>
                            <div className='d-flex align-items-center gap-3'>
                                {
                                    social?.content?.map((listsocial,a)=>
                                        <NavLink to={listsocial?.path} key={a}>
                                            <img src={'/assets/icons/'+listsocial?.img} width={social?.id === 2 ? 33 : 18} />
                                        </NavLink>
                                    )
                                }
                            </div>
                        </div>
                        )
                    }

                </div>
                <div className='col-lg-12'>
                    <div className='text-center'>
                        <p className='m-0 metropill'>
                            Copyright © by MPC 2025. All rights reserved.
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </div>
  )
}

export {NewFooter}