import React, { useState } from 'react'
import { NavLink } from 'react-router-dom'
import { Pagination, Typography } from 'antd'
import { DrawerBiomarker } from './BiomarkerDrawer'
import TextWithNoRead from '../../../components/TextWithNoRead'

const { Paragraph, Text } = Typography
const CardItem = ({ setVisible, data,isNotFound, selectedServiceType, setSearchValue,  searchValue, handleTypeChange, biomarkerData, isLoad }) => {

    const [drawerOpen, setDrawerOpen] = useState(false);
    const [selectedBiomarker, setSelectedBiomarker] = useState(null);
    const [biomarkers, setBiomarkers] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [pageSize, setPageSize] = useState(10);
    const [expanded, setExpanded ] = useState(false)
    const [sortOrder, setSortOrder] = useState(''); // Added state for sorting order
    const [filterOption, setFilterOption] = useState('');

    const itemRender = (_, type, originalElement) => {
        if (type === 'prev') {
          return <a className='metropill color-pre text-decoration-none'>Previous</a>;
        }
        if (type === 'next') {
          return <a className='metropill color-next text-decoration-none'>Next</a>;
        }
        return originalElement;
    };

    const closeDrawer = () => {
        setDrawerOpen(false);
        setSelectedBiomarker(null);
        document.body.style.overflow = 'auto';
    };

    const handleBiomarkerClick = (item) => {
        const biomarkersToShow = item.type === 'bio' ? [item._id] : item.biomarkers;
        const fetchedBiomarkers = biomarkerData.filter(biomarker => biomarkersToShow.includes(biomarker._id));
        setSelectedBiomarker(fetchedBiomarkers);
        setDrawerOpen(true);
        document.body.style.overflow = 'hidden';
    };

    // const paginatedData = data.slice((currentPage - 1) * pageSize, currentPage * pageSize);
    const resetFilters = () => {
        setSearchValue('');
        handleTypeChange('all');
        setCurrentPage(1);
        setPageSize(6)
        setSortOrder('');
    };

    function truncateText(text, wordLimit) {
        if (text.length > wordLimit) {
            let truncated = text.slice(0, wordLimit);
            const lastSpaceIndex = truncated.lastIndexOf(" "); // Find the last space
            if (lastSpaceIndex !== -1) {
              truncated = truncated.slice(0, lastSpaceIndex); // Remove the incomplete word
            }
            return truncated;
        }
        return text.trim(); // Also trims the input text if it's shorter than the limit
      }

      // Sort the data based on the selected sort order
      const sortData = (data) => {
        if (!filterOption) return data; // No sorting if no filter option is selected.
    
        const sortedData = [...data];
        if (filterOption === 'A to Z') {
          return sortedData.sort((a, b) => (a.name || '').localeCompare(b.name || ''));
        }
        if (filterOption === 'Z to A') {
          return sortedData.sort((a, b) => (b.name || '').localeCompare(a.name || ''));
        }
        if (filterOption === 'High to Low') {
          return sortedData.sort((a, b) => (b.price || 0) - (a.price || 0));
        }
        if (filterOption === 'Low to High') {
          return sortedData.sort((a, b) => (a.price || 0) - (b.price || 0));
        }
        return sortedData;
      };
      const sortedData = sortData(data);
  const paginatedData = sortedData.slice((currentPage - 1) * pageSize, currentPage * pageSize);

  return (
    <div className='pad-content'>
        <h4 className='h4-cs mb-7 text-center'>
            All Tests
        </h4>
        <p className='fs-18 m-0 metropill text-black text-center'>
            Our advanced blood testing services provide accurate insights for early detection and personalised care. Whether you're seeking routine screenings for early detection or specific tests for monitoring chronic conditions, our facilities and experienced professionals ensure accurate results.
        </p>
        <p className='fs-18 mb-10 metropill text-black text-center'>
            Search by specific blood tests or explore our health categories below. 
        </p>
        <div className='row g-4'>
            <div className='col-lg-9 col-md-8'>
                <div className="input-group search-grp">
                    <input type="text" className="form-control border-0 bg-transparent" 
                        placeholder='Search For Blood Test' aria-describedby='searchfield' 
                        value={searchValue}
                        onChange={(event) => setSearchValue(event.target.value)}
                        onKeyDown={(event) => {
                            if (event.key === "Enter" || event.code === "Search" || event.key === "Search") {
                                event.target.blur(); // This will hide the keyboard on mobile
                            }
                        }}
                    />
                    <span className="input-group-text bg-transparent border-0 " id="searchfield">
                        <img src="/assets/icons/search-ic.png" width={20} alt="" />
                    </span>
                </div>
            </div>
            <div className='col-lg-3 col-md-4'>
                <select
                    className="form-select filter-select"
                    value={filterOption}
                    onChange={(e) => setFilterOption(e.target.value)}
                >
                    <option value="">Select Filter</option>
                    <option value="A to Z">A to Z</option>
                    <option value="Z to A">Z to A</option>
                    <option value="High to Low">High to Low</option>
                    <option value="Low to High">Low to High</option>
                </select>
            </div>
        </div>
        <div className='row'>
            <div className='col-md-7'>
                <ul className="nav nav-pills mb-3 mt-10 filter-tab">
                    <li className="nav-item">
                        <button className={ selectedServiceType === 'all' ? "nav-link active" : "nav-link" }  
                            onClick={()=>handleTypeChange('all')}
                        >
                            <span className='metropill'>All</span>
                        </button>
                    </li>
                    <li className="nav-item">
                        <button className={ selectedServiceType === 'bio' ? "nav-link active" : "nav-link" }  
                        onClick={()=>handleTypeChange('bio')}
                    >
                            <span className='metropill'>Biomarkers</span>
                        </button>
                    </li>
                    <li className="nav-item">
                        <button className={ selectedServiceType === 'pro' ? "nav-link activeg" : "nav-link" }
                            onClick={()=>handleTypeChange('pro')}
                        >
                            <span className='metropill'>Profiles</span>
                        </button>
                    </li>
                </ul>
            </div>
            <div className='col-md-5'>
                <div className='d-flex align-items-center justify-content-lg-end justify-content-center gap-5 mt-10'>
                    <button type='button' onClick={resetFilters} className='bg-transparent border-0 fs-18 metropill'>
                        Reset Filters
                    </button>
                    <Pagination
                        className='toppaginationFilter'
                        defaultCurrent={1}
                        pageSize={pageSize}
                        onChange={(page, size) => {
                            setCurrentPage(page);
                            setPageSize(size);
                        }}
                        pageSizeOptions={['6', '10', '20', '50']}
                        showSizeChanger
                    />
                </div>
            </div>
        </div>
        <div className="tab-content">
            <div>
                <div className='row gap-c my-5 py-5'>
                {isLoad && 
                    <div className='col-md-12 text-center' style={{marginTop:'50px'}}>
                        <span className="conslut-loading"></span>
                    </div>
                }
                {
                    !isLoad && paginatedData && paginatedData.length > 0 &&
                    paginatedData.map((item, index) => ((
                        <div className='col-lg-4 col-md-6 col-sm-6' key={index}>
                            <div className='card card-az h-100'>
                                <div className='card-header h-100 bg=transparent border-0'>
                                    <NavLink to={`/a-z-tests/${item?._id}?type=${item?.type}`} className='text-decoration-none'>
                                        <h6 className='text-white text-center fs-21'>
                                            {item.type === 'bio' ? item.biomaker : item.name}
                                        </h6>
                                    </NavLink>
                                </div>
                                <div className='card-body'>
                                    <div className={ item.type === 'pro' ? "cricle-amount bg-green" : "cricle-amount" }>
                                        <h4 className='text-white text-center m-0 fs-25'>£ {item?.price}</h4>
                                    </div>
                                    <Paragraph
                                        
                                        className='text-white metrophofont font-p mb-0 fs-14'
                                    >
                                        {truncateText(item?.description, 250)}
                                    </Paragraph>
                                </div>
                                <div className='card-footer'>
                                    <button className='btn-drop justify-content-center' onClick={() => handleBiomarkerClick(item, selectedServiceType)}>
                                        <img src="/assets/icons/drop-b.png" width={22} alt="" />
                                        <span className='metropill'>
                                            {item.type === 'bio' ? 1 : item.biomarkers.length} BIOMARKER ANALYSED
                                        </span>
                                    </button>
                                    <div className='d-flex justify-content-center pb-4 gap-3 flex-lg-row flex-column'>
                                        <button className='quick-btn border-0 bg-transparent'>
                                            <span className='metropill'>
                                                QUICK ADD
                                            </span>
                                        </button>
                                        <div className='text-center'>
                                            <NavLink to={`/a-z-tests/${item?._id}?type=${item?.type}`} className='linkcs metropill'>
                                                Read More <img src='/assets/icons/visible.png' width={12} />
                                            </NavLink>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )))
                }
                </div>
            </div>
            {
                !isLoad &&
                data.length > 5 &&
                <div className='py-5'>
                    <Pagination
                        itemRender={itemRender}
                        defaultCurrent={1}
                        total={data.length}
                        pageSize={pageSize}
                        onChange={(page, size) => {
                            setCurrentPage(page);
                            setPageSize(size);
                            window.scrollTo({
                                top: 1000,
                                behavior: 'smooth', // Optional: Smooth scrolling
                              });
                        }}
                        pageSizeOptions={['6', '10', '20', '50']}
                        showSizeChanger
                    />
                </div>
            }
        </div>
        <DrawerBiomarker 
            visible={drawerOpen}
            onClose={closeDrawer}
            biomarkerData={selectedBiomarker}
        />
    </div>
  )
}

export {CardItem}