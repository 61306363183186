import React from 'react'
import { NewNavbar, BreadCrumbsNew, NewFooter } from '../../components';
import { TabAzTest } from './BloodTestcomponents/TabAzTest';

const AllBloodTest = () => {
  
  const breadcrumbs = [
    { label: 'Home', path: 'https://www.beta.myprivatechemist.com/' },
    { label: 'Blood Testing', path: 'https://www.beta.myprivatechemist.com/blood-testing/' },
    { label: 'A-Z Tests' },
  ];


  return (
    <div>
        <div className='breadcrumnb-img-bg'>
          <div className='container'>
            <NewNavbar />
            <BreadCrumbsNew breadcrumbs={breadcrumbs} title={'A-Z Tests'} />
          </div>
        </div>
        <div className='container my-5'>
            <TabAzTest />
        </div>
        <NewFooter />
    </div>
  )
}

export {AllBloodTest}