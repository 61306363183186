import React, { useEffect, useState } from 'react'
import { NewNavbar, BreadCrumbsNew, NewFooter } from '../../components';
import { SingleViewTabs } from '../../components/SingleViewComponents';
import { useParams, useLocation } from 'react-router-dom';
import { post } from '../../services/ApiService';
import 'react-inner-image-zoom/lib/InnerImageZoom/styles.css'
import InnerImageZoom from 'react-inner-image-zoom'
import { BloodTestContactForm } from './BloodTestcomponents/BloodTestContactForm';


const BloodTestDetail = () => {
    const { id } = useParams()
    const location = useLocation();
    const type = new URLSearchParams(location.search).get('type')
    const [isLoading, setIsLoading] = useState(true);
    const [isNotFound, setNotFound] = useState(false);
    const [ data, setData ] = useState(null)

    const breadcrumbs = [
        { label: 'Home', path: 'https://www.beta.myprivatechemist.com/' },
        { label: 'Blood Testing', path: 'https://www.beta.myprivatechemist.com/blood-testing/' },
        { label: 'A-Z Tests', path: '/a-z-tests' },
        { label: data?.name },
    ];


    const fetchData = async () => {
        try {
            setIsLoading(true);
            const body = {
                type
            }
            console.log('type',type)
            const res = await post(`api/package/${id}`,body);
            if (res.success) {
                setData(res.data)
                setIsLoading(false);
                setNotFound(false)
            } else {
              setIsLoading(false);
              setNotFound(true);
            }
        } catch (error) {
            setIsLoading(false);
            setNotFound(true);
            console.error(error.message);
        }
      };

      useEffect(()=>{
        fetchData()
      },[id])

      function truncateText(text, wordLimit) {
        if (typeof text !== "string" || typeof wordLimit !== "number") {
          return "";
        }
      
        if (text.length > wordLimit) {
            let truncated = text.slice(0, wordLimit);
            const lastSpaceIndex = truncated.lastIndexOf(" "); // Find the last space
            if (lastSpaceIndex !== -1) {
              truncated = truncated.slice(0, lastSpaceIndex); // Remove the incomplete word
            }
            return truncated;
        }
        return text.trim(); // Trims even if it's shorter than the limit
      }
  return (
    <div className=''>
        <div className='breadcrumnb-img-bg'>
          <div className='container'>
            <NewNavbar />
            <BreadCrumbsNew breadcrumbs={breadcrumbs} title={data?.name} />
          </div>
        </div>
        <div className='container my-15'>
            <div className='row'>
                <div className='col-lg-5'>
                    <div className='h-575'>
                        {/* <InnerImageZoom
                            src="/assets/icons/single.jpg"
                            zoomSrc="/assets/icons/single.jpg"
                            zoomType="hover"
                            zoomPreload={true}
                        /> */}
                        <img src="/assets/icons/single.jpg" className='img-fluid h-100 w-100' alt="" />
                    </div>
                </div>
                <div className='col-lg-7'>
                    <div className='Card border-0 bg-transparent h-100'>
                        <div className='card-body'>
                            <h1 className='main-text fs-45 m-lg-0 mt-8'>£ {data?.price}</h1>
                            <p className='metropill fs-6 my-10 my-md-13 text-justify'>
                                {truncateText(data?.description, 400)}
                            </p>
                        </div>
                        <div className='card-footer'>
                            <button type='button' className='btncust btn-pad w-auto metropill'>
                                ADD TO BASKET
                            </button>
                        </div>
                        <div className='mt-md-15 mt-10'>
                            <ul className='navbar-nav flex-column gap-2 align-items-md-start align-items-center'>
                                <li>
                                    <div className='d-flex gap-5 align-items-center'>
                                        <img src="/assets/icons/plus-thin.png" width={10} alt="" />
                                        <span className='fs-16 metropill-thin'>
                                            SKU: 567808
                                        </span>
                                    </div>
                                </li>
                                <li>
                                    <div className='d-flex gap-5 align-items-center'>
                                        <img src="/assets/icons/plus-thin.png" width={10} alt="" />
                                        <span className='fs-16 metropill-thin'>
                                            SKU: 567808
                                        </span>
                                    </div>
                                </li>
                                <li>
                                    <div className='d-flex gap-5 align-items-center'>
                                        <img src="/assets/icons/plus-thin.png" width={10} alt="" />
                                        <span className='fs-16 metropill-thin'>
                                            SKU: 567808
                                        </span>
                                    </div>
                                </li>
                                <li>
                                    <div className='d-flex gap-5 align-items-center'>
                                        <img src="/assets/icons/plus-thin.png" width={10} alt="" />
                                        <span className='fs-16 metropill-thin'>
                                            SKU: 567808
                                        </span>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div className='col-lg-12'>
                    <SingleViewTabs 
                        data={data}
                    />
                </div>
            </div>
        </div>
        <BloodTestContactForm />
        <NewFooter />
    </div>
  )
}

export {BloodTestDetail}