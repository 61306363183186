import React, { useState } from 'react'
import './index.css'
import { Link } from 'react-router-dom'
import Spinner from 'react-bootstrap/Spinner';
const MobileFilter = ({selectedItems, buildPkgRecomendation, isLoading, isRecommending, setIsRecommending, handleRemove}) => {

    const [ state, setState ] = useState(false)
    const [isCollapseOpen, setIsCollapseOpen] = useState(false);

    const toggleCollapse = () => {
        setIsCollapseOpen(!isCollapseOpen);
    }

    const handleButtonClick = () => {
        if (isCollapseOpen) {
            setIsCollapseOpen(false);
        }
    }
    

  return (
    <div className='bg-filter-mbl'>
        
        <div className={`p-5 collapse border-top border-dark ${isCollapseOpen ? 'show' : ''}`} id='collapseFilter'>
            <div> 
                <h6>Selected Items</h6>
                <div className='d-flex align-items-center flex-wrap mt-5 gap-6s pb-5'>
                    {selectedItems.length > 0 ? (
                        selectedItems.map((item, index) => (
                        <div class="tag-btn text-decoration-none gap-3 text-capitalize" key={index}> 
                            {item?.name}
                            <Link onClick={() => handleRemove(item.id)} className='fs-20 text-decoration-none'>×</Link>
                        </div>
                        ))
                    ):(
                        <p className='fs-14 text-center w-100 metropill'>No item selected yet!</p>
                    )}
                </div>
            </div>
          
        </div>  
        <div className='d-flex align-items-center bg-main w-100 p-4'>
            <div className='flex-grow-1 d-flex justify-content-center'>
                <button type='button' 
                    className='border-0 bg-transparent'
                    onClick={toggleCollapse}
                    data-bs-toggle='collapse' data-bs-target='#collapseFilter'
                    >
                    <img
                        src={isCollapseOpen ? "/assets/images/down-arrow.png" : "/assets/images/upward.png"}
                        width={isCollapseOpen ? '40':'28'}
                        alt=""
                    />
                </button> 



            </div>
            {isRecommending ? (
                <button type='button' 
                    className={
                        selectedItems.length !== 0  ? 
                        'border-0 bg-white sticky-btn d-block flex-grow-1 ' :
                        'border-0 bg-gray-inactive sticky-btn d-block flex-grow-1 ' 
                    }
                    disabled={selectedItems.length === 0 ? true : false}
                    onClick={() => {
                        setIsRecommending(false);
                        handleButtonClick();
                    }}
                    >
                    <span>Edit My Package</span>
                </button> 
            ):(
                <button type='button' 
                    className={
                        selectedItems.length !== 0 || isLoading  ? 
                        'border-0 bg-active sticky-btn d-block text-white flex-grow-1' :
                        'border-0 bg-gray-inactive  sticky-btn d-block flex-grow-1' 
                    }
                    disabled={selectedItems.length === 0 || isLoading ? true : false}
                    onClick={() => {
                        buildPkgRecomendation();
                        handleButtonClick();
                    }}
                    >
                    <span> 
                        {isLoading && <Spinner animation="border" variant="light" size="sm" className="mr-2" />}                       
                        {selectedItems.length === 0  ? 'Add Tests to Build Package' : ' Build My Package'}
                    </span>
                </button> 
            )}

        </div> 
    </div>
  )
}

export {MobileFilter}