import React, { useState, useEffect, useRef } from "react";
import Header from "../components/Header";
import PageLoader from "../components/PageLoader";
import "./CheckoutStyle.css";
const AppointmentCancel = () => {
  return (
    <>
          <Header />
      <main id="content" className="wrapper layout-page">
      <section className="container pb-14 pb-lg-19" style={{marginTop:"100px"}}>

        <div className="row">
            <div className="col-lg-2 col-md-2 col-sm-12"></div>
            <div className="col-lg-8 col-md-6 col-sm-12">
                <div className="card shadow app-card">
                    <div className="card-body text-center">
                    <h3 className="theme-font mt-5">Sorry!</h3>
                    <h6 className="theme-font mt-5">Payment Wasn't Successfull</h6>
                <p className="m-0 p-0 app-detail">We Scheduled your appointment with pay later status.</p>
                <p className="m-0 p-0 app-detail">You can pay on your arrival.</p>
                <button className="btn btn-outline-primary mt-5 rounded-3 theme-font">Explore More</button>
                    </div>
                </div>

            </div>
            <div className="col-lg-2 col-md-2 col-sm-12 text-left"></div>
        </div>
        </section>

      </main>
    </>
  )
}

export default AppointmentCancel