import React, { useState, useEffect, useRef } from "react";
import { post,del } from '../../services/ApiService.js';
import Navbar from "../../components/Navbar/index.jsx";
import { BreadCrumb } from "../../components/Navbar/BreadCrumb.js";
import Footer from "../../components/Footer.jsx";
import PageLoader from "../../components/PageLoader.js";
import './index.css'
import { Link, useNavigate } from "react-router-dom";
import { Button, message, Popconfirm } from 'antd';
import axios from 'axios';
import { BreadCrumbsNew, NewFooter, NewNavbar } from "../../components/index.jsx";

const Cart = () => {
  const [userToken, setUserToken] = useState('');
  const [cartItems, setCartItems] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isNotFound, setNotFound] = useState(false);
  const [isBloodDraw, setIsBloodDraw] = useState(false);
  const navigate = useNavigate()

  useEffect(() => {
    CartData();
  }, []);

  // useEffect(() => {
  //   if (userToken) {
  //     CartData();
  //   }
  // }, [userToken]);


  const fetchUserToken = async () => {
    try {
      const response = await axios.get('https://server.myprivatechemist.com/set-user-token', {
        withCredentials: true,
      });
      const token = response.data.userToken;

      if (token) {
        setUserToken(token);
      } else {
        setIsLoading(false);
        setNotFound(true);
        setCartItems([]);
      }
    } catch (error) {
      console.error('Error fetching user token:', error);
      setIsLoading(false);
      setNotFound(true);
    }
  };

  const CartData = async () => {

    try {

        setIsLoading(true);
        const Body = {
          userToken,
        };
        const res = await post(`api/cart/items`, Body);
        if (res.success) {
          setIsLoading(false);
          setCartItems(res.data);
          setNotFound(false)
          const bloodService = res.data.find(item => item.category === "report-category");
          if (bloodService) setIsBloodDraw(true);
        } else {
          setCartItems([]);
          setIsLoading(false);
          setNotFound(true);
        }
        window.scrollTo({
          top: 0,
          behavior: 'smooth', // Optional: Smooth scrolling
        });
    } catch (error) {
        setCartItems([]);
        setIsLoading(false);
        setNotFound(true);
        console.error(error.message);
    }
  };

  const deleteCartItem = async (id) => {
    const res = await del(`api/cart/item`, id);
    if (res.success) {
      message.success('Item deleted from cart');
      const cartCounterElement = document.querySelector('.counterCart');
      if (cartCounterElement) {
        let currentCounter = parseInt(cartCounterElement.innerText, 10);
        if (currentCounter > 0) {
          const newCount = currentCounter - 1;
          if(newCount < 0 ){
            cartCounterElement.innerText = 0;
          }else{
            cartCounterElement.innerText = currentCounter - 1;
          }
        }
      }
      CartData();
    } else {
      console.log(res);
      message.error('Failed to delete item');

    }
  };

  const cancel = (e) => { };
  
  const calculateSubtotal = () => {
    let subtotal = 0;
    if(cartItems.length !==0){
      cartItems.forEach((item) => {
        if (item.type === 'Service') {
          subtotal += item.price;
        } else if (item.type === 'Add-Ons') {
          subtotal += item.discountPrice;
        }
      });
    }


    return subtotal;
  };
  const calculateTotal = () => {
    const subtotal = calculateSubtotal();
    let total; 
    if(isBloodDraw){
      total = subtotal + 30;
    }else{
      total= subtotal;
    }
    return total;
  };
  const breadcrumbs = [
    { label: 'Home', path: 'https://www.beta.myprivatechemist.com/' },
    { label: 'Cart' },
  ];


  return (
    <>
      {/* <Header /> */}
      <main id="content" className="wrapper layout-page">
          <div className='breadcrumnb-img-bg'>
            <div className='container'>
              <NewNavbar />
              <BreadCrumbsNew breadcrumbs={breadcrumbs} title={'Cart'} />
            </div>
          </div>
            <div className="container">
              {isLoading && <PageLoader />} 
              {cartItems && cartItems.length > 0 && (
                <>
                <div className="cart" style={{marginTop:'70px'}}>
                  <table className="table cart-table">
                    <thead className="table-header">
                      <tr>
                        <th scope="col"></th>
                        <th scope="col">Product</th>
                        <th scope="col">Qty</th>
                        <th scope="col">Price</th>
                      </tr>
                    </thead>
                    <tbody>
                    {cartItems.map((item, index) => (
                      <tr key={index}>
                          <td className="text-center">
                            <Popconfirm
                              title="Delete"
                              description="Are you sure ?"
                              onConfirm={() => deleteCartItem(item._id)}
                              onCancel={cancel}
                              okText="Yes"
                              cancelText="No"
                            >
                              <Link  ><i className="fa fa-close"></i></Link>
                            </Popconfirm>
                          </td>
                          <td>
                              <div class="product-name">{item.name}</div>
                          </td>
                          <td className="">1</td>
                          <td class="price ">£{item.type === "Service" ? item.price:item.discountPrice}</td>
                      </tr>
                    ))}
                      
                  </tbody>
                  </table>
                </div>
                <div className="row total-row mb-4">
                  <div className="col-lg-6 col-md-6 col-sm-12"></div>
                  <div className="col-lg-6 col-md-6 col-sm-12">
                  <div className="cart-totals">
                    <h4>Cart totals</h4>
                    <div className="row total-row">
                      <div className="col-4 totel-left-heading"> <span>Subtotal</span></div>
                      <div className="col-8 totel-right-content"><span className=""> £{calculateSubtotal()}</span> </div>
                    </div>
                    {isBloodDraw &&
                      <div className="row total-row">
                        <div className="col-4 totel-left-heading"><span>Blood Draw Fee</span> </div>
                        <div className="col-8 totel-right-content"><span className="">£ 30</span> </div>
                      </div>
                    }
                    <div className="row total-row">
                      <div className="col-4 totel-left-heading"><span>Total</span> </div>
                      <div className="col-8 totel-right-content"> <b className=" text-dark">£ {calculateTotal()}</b></div>
            
                    </div>
                    <div className="row text-start">
                      <Link to={'/checkout'} className="btn-show w-100 btn-checkout metropill" style={{textDecoration:'none'}}>PROCEED TO CHECKOUT</Link>
                    </div>

                  </div>

                  </div>
                </div>
                </>
              )}
              {isNotFound && 
                  <div className="row p-5" style={{marginTop:'100px', marginBottom:'100px'}}>
                    <div className="col-lg-2 col-12"></div>
                    <div className="col-lg-4 col-12 " >
                      <img src="/assets/images/empty-cart.png" alt="" style={{width:'250px'}} />
                    </div>
                    <div className="col-lg-4 col-12">
                      <div>
                        <h2 >Sorry...</h2>
                        <p className="error-desc metropill">Your cart is currently empty. We invite you to explore our available services to find the perfect option for you.</p>
                        <Link to={'https://www.beta.myprivatechemist.com'} className="btncust btn-pad w-auto d-inline-block text-decoration-none metropill">Explore</Link>
                      </div>
                    </div>
                    <div className="col-lg-2 col-12"></div>
                  </div>
              }
            </div>

            <NewFooter />

      </main>
    </>
  );
};

export default Cart;
