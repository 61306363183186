import React, { useEffect, useState } from 'react'
import { Drawer } from 'antd'
import { CloseOutlined, FacebookOutlined, InstagramOutlined, SearchOutlined, TwitterOutlined } from '@ant-design/icons'
import './index.css'
import { NavLink } from 'react-router-dom'
import { get } from '../../../services/ApiService'

const MenuMobile = ({visible,onClose}) => {

  const [counter, setCounter] = useState(0);

  useEffect(()=>{
    CartCounter();
  },[])

const CartCounter = async () => {

    try {
        const res = await get(`api/cart/count`);
        if (res.success) {
            setCounter(res.count)
        } 
    } catch (error) {

        console.error(error.message);
    }
  };

  return (
    <Drawer
        title={null}
        placement={'top'}
        onClose={onClose}
        open={visible}
        height={'100%'}
        closeIcon={false}
        className='bg-drawer'
      >
        <button className="closeicons" onClick={onClose}>
          <CloseOutlined />
        </button>
        <div >
          <div className='d-flex justify-content-center logo-mbl'>
            <img src="/logo.png" alt="" width={120} />
          </div>
          <div className='w-28rems'>
              <ul className='ul-menus'>
                <li>
                  <NavLink to={''} className='text-uppercase metropill'>
                    home
                  </NavLink>
                </li>
                <li>
                  <div class="accordion bg-transparent accordion-flush cust-accords" id="aboutaccord">
                    <div class="accordion-item bg-transparent">
                      <h2 class="accordion-header" id="flush-abouthead">
                        <div className='d-flex justify-content-between align-items-center w-100 hover-accord'>
                          <NavLink to={'https://www.beta.myprivatechemist.com/blood-testing/'} className='text-uppercase metropill d-block w-auto'>
                            Blood Testing
                          </NavLink>
                          <button class="accordion-button w-auto bg-transparent d-block text-end collapsed bg-transparent border-0 p-0 metropill" type="button" data-bs-toggle="collapse" data-bs-target="#flush-about" aria-expanded="false" aria-controls="flush-about">
                            <div className='d-flex justify-content-end plus-div'>
                              <img src="/assets/icons/plus-w.png" alt="" className='white-ic' width={10} />
                              <img src="/assets/icons/plus-p.png" alt="" className='pink-ic text-end' width={10} />
                            </div>
                            <div className='d-flex justify-content-end'>
                              <img src="/assets/icons/minus-w.png" alt="" className='minus-w-ic' width={10} />
                              <img src="/assets/icons/minus-p.png" alt="" className='minus-p-ic text-end' width={10} />
                            </div>
                          </button>
                        </div>
                      </h2>
                      <div id="flush-about" class="accordion-collapse collapse" aria-labelledby="flush-abouthead" data-bs-parent="#aboutaccord">
                        <div class="accordion-body py-2 pe-0">
                          <ul className='ul-menuinner m-0'>
                            <li>
                              <NavLink to={'/a-z-tests'} className='text-uppercase metropill inner-navlink'>
                                A-Z Tests
                              </NavLink>
                            </li>
                            <li>
                              <NavLink to={'/'} className='text-uppercase metropill inner-navlink'>
                                build my package
                              </NavLink>
                            </li>
                            <li>
                              <div class="accordion-item bg-transparent">
                                <h2 class="accordion-header" id="flush-healthhead">
                                  <div className='d-flex justify-content-between align-items-center w-100 hover-accord'>
                                    <NavLink to={''} className='text-uppercase metropill d-block w-auto'>
                                      health concern
                                    </NavLink>
                                    <button class="accordion-button bg-transparent d-block text-end collapsed bg-transparent border-0 p-0 metropill w-auto"  type="button" data-bs-toggle="collapse" data-bs-target="#flush-health" aria-expanded="false" aria-controls="flush-health">
                                      <div className='d-flex justify-content-end plus-div'>
                                        <img src="/assets/icons/plus-w.png" alt="" className='white-ic' width={10} />
                                        <img src="/assets/icons/plus-p.png" alt="" className='pink-ic text-end' width={10} />
                                      </div>
                                      <div className='d-flex justify-content-end'>
                                        <img src="/assets/icons/minus-w.png" alt="" className='minus-w-ic' width={10} />
                                        <img src="/assets/icons/minus-p.png" alt="" className='minus-p-ic text-end' width={10} />
                                      </div>
                                    </button>
                                  </div>
                                </h2>
                                <div id="flush-health" class="accordion-collapse collapse" aria-labelledby="flush-healthhead" data-bs-parent="#healthaccord">
                                  <div class="accordion-body py-2">
                                    <ul className='ul-menuinner m-0'>
                                      <li>
                                        <NavLink to={''} className='text-uppercase metropill inner-navlink'>
                                          general health tests
                                        </NavLink>
                                      </li>
                                      <li>
                                        <NavLink to={''} className='text-uppercase metropill inner-navlink'>
                                          mens health
                                        </NavLink>
                                      </li>
                                      <li>
                                        <NavLink to={''} className='text-uppercase metropill inner-navlink'>
                                          mood tests
                                        </NavLink>
                                      </li>
                                      <li>
                                        <NavLink to={''} className='text-uppercase metropill inner-navlink'>
                                          nutrition
                                        </NavLink>
                                      </li>
                                      <li>
                                        <NavLink to={''} className='text-uppercase metropill inner-navlink'>
                                          sexual health/fertility
                                        </NavLink>
                                      </li>
                                      <li>
                                        <NavLink to={''} className='text-uppercase metropill inner-navlink'>
                                          sports/fitness
                                        </NavLink>
                                      </li>
                                      <li>
                                        <NavLink to={''} className='text-uppercase metropill inner-navlink'>
                                          tiredness & fatigue tests
                                        </NavLink>
                                      </li>
                                      <li>
                                        <NavLink to={''} className='text-uppercase metropill inner-navlink'>
                                          weight
                                        </NavLink>
                                      </li>
                                      <li>
                                        <NavLink to={''} className='text-uppercase metropill inner-navlink'>
                                          women's health
                                        </NavLink>
                                      </li>
                                    </ul>
                                  </div>
                                </div>
                              </div>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </li>
                <li>
                  <NavLink to={''} className='text-uppercase metropill'>
                    travel clinic
                  </NavLink>
                </li>
                <li>
                  <NavLink to={''} className='text-uppercase metropill'>
                    treatment & medication
                  </NavLink>
                </li>
                <li>
                  <NavLink to={''} className='text-uppercase metropill'>
                    our mission
                  </NavLink>
                </li>
                <li>
                  <NavLink to={''} className='text-uppercase metropill'>
                    contact
                  </NavLink>
                </li>
              </ul>
              <div class="input-group custom-search mb-3">
                <input type="text" className="form-control border-0 p-0 fs-16 bg-transparent" placeholder="Search" aria-label="Recipient's username" aria-describedby="basic-addon2" />
                <span className="input-group-text border-0 p-0 bg-transparent" id="basic-addon2">
                  <SearchOutlined />
                </span>
              </div>
              <div className='d-flex align-items-center justify-content-center gap-3 pt-4'>
                  <NavLink to={''} className='social-icons'>
                      <FacebookOutlined />
                  </NavLink>
                  <NavLink to={''} className='social-icons'>
                      <TwitterOutlined />
                  </NavLink>
                  <NavLink to={''} className='social-icons'>
                      <InstagramOutlined />
                  </NavLink>
                  {/* <div className="cart-nav-1">
                    <span className='counterCart'>
                      <span>{counter}</span>
                    </span>
                    <NavLink to="/cart" >
                      <img src="/assets/icons/cart.png" style={{filter:'invert(1)'}} className='cartone' width={24} alt="" />
                    </NavLink>
                  </div> */}
              </div>
          </div>
        </div>
      </Drawer>
  )
}

export default MenuMobile