const BASE_URL = process.env.REACT_APP_API_ENDPOINT;
// const BASE_URL = "http://localhost:5000";
// const BASE_URL = "https://server.myprivatechemist.com";

// Function to handle GET requests
async function get(endpoint) {
  const url = `${BASE_URL}/${endpoint}`;
  try {
    const response = await fetch(url, {
      method: 'GET',
      credentials: 'include',
    });
    if (!response.ok) {
      throw new Error('Network response was not ok');
    }
    const data = await response.json();
    return data;
  } catch (error) {
    console.error('Error fetching data:', error);
    throw error;
  }
}

// Function to handle POST requests
async function post(endpoint, body) {
  const url = `${BASE_URL}/${endpoint}`;
  try {
    const response = await fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      credentials: 'include', // Include cookies in the request
      body: JSON.stringify(body)
    });
    if (!response.ok) {
      throw new Error('Network response was not ok');
    }
    const data = await response.json();
    return data;
  } catch (error) {
    console.error('Error posting data:', error.message);
    throw new Error(error);
  }
}

// Function to handle DELETE requests
async function del(endpoint, id) {
  const url = `${BASE_URL}/${endpoint}/${id}`;
  try {
    const response = await fetch(url, {
      method: 'DELETE',
      credentials: 'include',
    });
    if (!response.ok) {
      throw new Error('Network response was not ok');
    }
    const data = await response.json();
    return data;
  } catch (error) {
    console.error('Error deleting data:', error.message);
    throw new Error(error);
  }
}

// Export the functions for use in other modules
export { get, post, del };
