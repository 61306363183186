import React from "react";
import { NavLink } from 'react-router-dom'

const HealthConcernCards = () => {


    const data = [
        {
          img:'c-1.png',
          title:'General Health Tests'
        },
        {
          img:'c-2.jpg',
          title:"Men's Health"
        },
        {
          img:'c-3.jpg',
          title:'Mood Tests'
        },
        {
          img:'c-4.jpg',
          title:'Nutrition'
        },
        {
          img:'c-5.jpg',
          title:'Sexual Health/Fertility'
        },
        {
          img:'c-6.jpg',
          title:'Sports/Fitness'
        },
        {
          img:'c-7.jpg',
          title:'Tiredness & Fatigue Tests'
        },
        {
          img:'c-8.jpg',
          title:'Weight'
        },
        {
          img:'c-9.jpg',
          title:"Women's Health"
        },
      ]

  return (
    <div className="pad-t-5 text-center">
      <h1 className="fs-56">
        Search by <span className="main-text">Health Concerns</span>
      </h1>
      <p className="fs-18 metropill text-black">
        Not sure which test you need? Explore different tests by searching
        through a health concern.
      </p>
      <div className="row gap">
        {data?.map((card, index) => (
          <div className="col-lg-4 col-md-6 col-sm-6" key={index}>
            <NavLink to={''}>
            <div className="card bg-transparent border-0 mb-5">
              <div className="card-body p-0">
                <div className="block-img">
                  <img
                    src={"/assets/icons/" + card?.img}
                    className="img-fluid"
                    alt=""
                  />
                  <div className="captiondiv">
                    <h5 className="metropill m-0">{card?.title}</h5>
                  </div>
                </div>
                <div className="mt-4">
                  <NavLink to={""}>
                    <h5 className="m-0">{card?.title}</h5>
                  </NavLink>
                </div>
              </div>
            </div>
            </NavLink>
          </div>
        ))}
      </div>
    </div>
  );
};

export { HealthConcernCards };
