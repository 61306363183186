import React from 'react';
import PropTypes from 'prop-types';
import './index.css';
import { Flex, Typography } from 'antd';
import { NavLink } from 'react-router-dom';

const { Title } = Typography;

const BreadCrumbsNew = ({ breadcrumbs, title }) => {
    return (
        <div className='title flex-col-center-center py-5'>
            <div className='' style={{ marginTop: '5.6rem', paddingBottom: '40px' }}>
                <Title level={1} className='justify-center brand-color my-0 fs-5rem w-font' style={{ fontWeight: '400',}}>
                    {title}
                </Title>
                <Flex style={{ marginTop: '2.7rem',gap:12.4 }} wrap>
                    {breadcrumbs.map((breadcrumb, index) => (
                        <React.Fragment key={index}>
                            {breadcrumb.path ? (
                                <NavLink to={breadcrumb.path} className='hover-link'>
                                    <Typography.Text className="breadcrumb-link metropill-thin">
                                        {breadcrumb.label}
                                    </Typography.Text>
                                </NavLink>
                            ) : (
                                <Typography.Text className="breadcrumb-link metropill-thin">
                                    {breadcrumb.label}
                                </Typography.Text>
                            )}
                            {index < breadcrumbs.length - 1 && (
                                <Typography.Text className="breadcrumb-link metropill-thin" style={{fontSize:14.2}}>
                                    •
                                </Typography.Text>
                            )}
                        </React.Fragment>
                    ))}
                </Flex>
            </div>
        </div>
    );
};

// BreadCrumbsNew.propTypes = {
//     breadcrumbs: PropTypes.arrayOf(
//         PropTypes.shape({
//             label: PropTypes.string.isRequired,
//             path: PropTypes.string,
//         })
//     ).isRequired,
// };

export { BreadCrumbsNew };
