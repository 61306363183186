import React, { useEffect, useState } from "react";
import { CardItem } from "./CardItem.jsx";
import { DrawerBiomarker } from "./BiomarkerDrawer.jsx";
import { get, post } from '../../../services/ApiService.js';
import Slider from "react-slick";

const TabAzTest = () => {
  const [visible, setVisible] = useState(false);
  const [biomarkerData, setBiomarkers] = useState([]);
  const [selectedBiomarkers, setSelectedBiomarkers] = useState([]);
  const [isDataLoading, setIsDataLoading] = useState(true);
  const [isNotFound, setNotFound] = useState(false);
  const [data, setData] = useState([]);
  const [selectedServiceType, setSelectedServiceType] = useState("all");
  const [searchValue, setSearchValue] = useState("");
  const [isLoad, setIsLoad] = useState(true);

  var tabsSlider = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 5,
    slidesToScroll: 1,
    initialSlide: 0,
    centerMode: false,
    autoplay: false,
    arrows: true,
    nextArrow: (
      <div>
        <img src="/assets/icons/next.png" width={30} />
      </div>
    ),
    prevArrow: (
      <div>
        <img src="/assets/icons/prev.png" width={30} />
      </div>
    ),
    responsive: [
      {
        breakpoint: 1199,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 1,
          infinite: true,
          dots: false,
          arrows: true,
        },
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
          dots: false,
          arrows: true,
        },
      },
      {
        breakpoint: 800,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          initialSlide: 0,
        },
      },
      {
        breakpoint: 450,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 0,
        },
      },
    ],
  };

  const handleTypeChange = (value) => {
    setSelectedServiceType(value);
    setIsDataLoading(true);

  };

  useEffect(() => {
    const loadData = async () => {
      setIsLoad(true);
      try {
        await fetchData();
        await fetchBiomarkers();
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setIsLoad(false);
      }
    };

    if (selectedServiceType && selectedServiceType !== "") {
      loadData();
    }
  }, [selectedServiceType]);

  useEffect(() => {
    setSelectedServiceType("all");
    fetchData();
  }, [searchValue]);

  const fetchBiomarkers = async () => {
    try {
      const res = await get("api/package/biomarkers");
      if (res.success) {
        setBiomarkers(res.biomarkers);
      } else {
        setBiomarkers([]);
      }
    } catch (error) {
      setBiomarkers([]);
    }
  };

  const fetchData = async () => {
    try {
      setIsDataLoading(true);
      setNotFound(false);
      const res = await post("api/package/data", {
        type: selectedServiceType,
        search: searchValue,
      });
      
      if (res.success) {
        setData(res.data);
      } else {
        setData([]);
        setNotFound(true);
      }
      setIsDataLoading(false);
    } catch (error) {
      setData([]);
      setNotFound(true);
      setIsDataLoading(false);
    }
  };

  return (
    <div className="pt-12">
      <ul
        className="nav-pills mb-3 w-100 pill-cust hoverarrow p-0"
        id="pills-tab"
        role="tablist"
      >
        <Slider {...tabsSlider}>
          <li className="nav-item flex-grow-1 h-100" role="presentation">
            <button
              className="nav-link w-100 active d-flex flex-column align-items-center"
              id="pills-heartpulse-tab"
              data-bs-toggle="pill"
              data-bs-target="#pills-heartpulse"
              type="button"
              role="tab"
              aria-controls="pills-heartpulse"
              aria-selected="true"
            >
              <span>
                <img
                  src="/assets/icons/alltest-b.png"
                  className="icon-b"
                  width={30}
                  alt=""
                />
                <img
                  src="/assets/icons/alltest-w.png"
                  className="icon-w"
                  width={30}
                  alt=""
                />
              </span>
              <span className="metropill pt-5 pb-3">All Tests</span>
            </button>
          </li>
          <li className="nav-item flex-grow-1 h-100" role="presentation">
            <button
              className="nav-link w-100 d-flex flex-column align-items-center"
              id="pills-heartpulse-tab"
              data-bs-toggle="pill"
              data-bs-target="#pills-heartpulse"
              type="button"
              role="tab"
              aria-controls="pills-heartpulse"
              aria-selected="true"
            >
              <span>
                <img
                  src="/assets/icons/heartpulse-b.png"
                  className="icon-b"
                  width={30}
                  alt=""
                />
                <img
                  src="/assets/icons/heartpulse-w.png"
                  className="icon-w"
                  width={30}
                  alt=""
                />
              </span>
              <span className="metropill pt-5 pb-3">General Health Tests</span>
            </button>
          </li>
          <li className="nav-item flex-grow-1" role="presentation">
            <button
              className="nav-link w-100 d-flex flex-column align-items-center"
              id="pills-user-tab"
              data-bs-toggle="pill"
              data-bs-target="#pills-user"
              type="button"
              role="tab"
              aria-controls="pills-user"
              aria-selected="false"
            >
              <span>
                <img
                  src="/assets/icons/user-b.png"
                  className="icon-b"
                  width={30}
                  alt=""
                />
                <img
                  src="/assets/icons/user-w.png"
                  className="icon-w"
                  width={30}
                  alt=""
                />
              </span>
              <span className="metropill pt-5 pb-3">Mens Health</span>
            </button>
          </li>
          <li className="nav-item flex-grow-1" role="presentation">
            <button
              className="nav-link w-100 d-flex flex-column align-items-center"
              id="pills-mood-tab"
              data-bs-toggle="pill"
              data-bs-target="#pills-mood"
              type="button"
              role="tab"
              aria-controls="pills-mood"
              aria-selected="false"
            >
              <span>
                <img
                  src="/assets/icons/smile-b.png"
                  className="icon-b"
                  width={30}
                  alt=""
                />
                <img
                  src="/assets/icons/smile-w.png"
                  className="icon-w"
                  width={30}
                  alt=""
                />
              </span>
              <span className="metropill pt-5 pb-3">Mood Tests</span>
            </button>
          </li>
          <li className="nav-item flex-grow-1" role="presentation">
            <button
              className="nav-link w-100 d-flex flex-column align-items-center"
              id="pills-nutri-tab"
              data-bs-toggle="pill"
              data-bs-target="#pills-nutri"
              type="button"
              role="tab"
              aria-controls="pills-nutri"
              aria-selected="false"
            >
              <span>
                <img
                  src="/assets/icons/apple-b.png"
                  className="icon-b"
                  width={30}
                  alt=""
                />
                <img
                  src="/assets/icons/apple-w.png"
                  className="icon-w"
                  width={30}
                  alt=""
                />
              </span>
              <span className="metropill pt-5 pb-3">Nutrition</span>
            </button>
          </li>
          <li className="nav-item flex-grow-1" role="presentation">
            <button
              className="nav-link w-100 d-flex flex-column align-items-center"
              id="pills-health-tab"
              data-bs-toggle="pill"
              data-bs-target="#pills-health"
              type="button"
              role="tab"
              aria-controls="pills-health"
              aria-selected="false"
            >
              <span>
                <img
                  src="/assets/icons/hold-b.png"
                  className="icon-b"
                  width={30}
                  alt=""
                />
                <img
                  src="/assets/icons/hold-w.png"
                  className="icon-w"
                  width={30}
                  alt=""
                />
              </span>
              <span className="metropill pt-5 pb-3">
                Sexual Health/Fertility
              </span>
            </button>
          </li>
          <li className="nav-item flex-grow-1" role="presentation">
            <button
              className="nav-link w-100 d-flex flex-column align-items-center"
              id="pills-supp-tab"
              data-bs-toggle="pill"
              data-bs-target="#pills-supp"
              type="button"
              role="tab"
              aria-controls="pills-supp"
              aria-selected="false"
            >
              <span>
                <img
                  src="/assets/icons/fitness-b.png"
                  className="icon-b"
                  width={30}
                  alt=""
                />
                <img
                  src="/assets/icons/fitness-w.png"
                  className="icon-w"
                  width={30}
                  alt=""
                />
              </span>
              <span className="metropill pt-5 pb-3">
                Suports/Fitness
              </span>
            </button>
          </li>
          <li className="nav-item flex-grow-1" role="presentation">
            <button
              className="nav-link w-100 d-flex flex-column align-items-center"
              id="pills-ft-tab"
              data-bs-toggle="pill"
              data-bs-target="#pills-ft"
              type="button"
              role="tab"
              aria-controls="pills-ft"
              aria-selected="false"
            >
              <span>
                <img
                  src="/assets/icons/tired-b.png"
                  className="icon-b"
                  width={30}
                  alt=""
                />
                <img
                  src="/assets/icons/tired-w.png"
                  className="icon-w"
                  width={30}
                  alt=""
                />
              </span>
              <span className="metropill pt-5 pb-3">
                Tiredness & Fatigue Tests
              </span>
            </button>
          </li>
          <li className="nav-item flex-grow-1" role="presentation">
            <button
              className="nav-link w-100 d-flex flex-column align-items-center"
              id="pills-wg-tab"
              data-bs-toggle="pill"
              data-bs-target="#pills-wg"
              type="button"
              role="tab"
              aria-controls="pills-wg"
              aria-selected="false"
            >
              <span>
                <img
                  src="/assets/icons/weight-b.png"
                  className="icon-b"
                  width={30}
                  alt=""
                />
                <img
                  src="/assets/icons/weight-w.png"
                  className="icon-w"
                  width={30}
                  alt=""
                />
              </span>
              <span className="metropill pt-5 pb-3">
                Weight
              </span>
            </button>
          </li>
          <li className="nav-item flex-grow-1" role="presentation">
            <button
              className="nav-link w-100 d-flex flex-column align-items-center"
              id="pills-wm-tab"
              data-bs-toggle="pill"
              data-bs-target="#pills-wm"
              type="button"
              role="tab"
              aria-controls="pills-wm"
              aria-selected="false"
            >
              <span>
                <img
                  src="/assets/icons/women-b.png"
                  className="icon-b"
                  width={30}
                  alt=""
                />
                <img
                  src="/assets/icons/women-w.png"
                  className="icon-w"
                  width={30}
                  alt=""
                />
              </span>
              <span className="metropill pt-5 pb-3">
                women's health
              </span>
            </button>
          </li>
        </Slider>
      </ul>
      <div className="tab-content">
        <div>
            <CardItem 
                data={data}
                isNotFound={isNotFound} 
                selectedServiceType={selectedServiceType}
                setSearchValue={setSearchValue}  
                searchValue ={searchValue} 
                handleTypeChange={handleTypeChange} 
                biomarkerData={biomarkerData}
                isLoad={isLoad}
            />
        </div>
      </div>
      
    </div>
  );
};

export { TabAzTest };
