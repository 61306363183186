import React from 'react';
import 'react-modern-drawer/dist/index.css';
import { Collapse, Drawer, Flex, Typography } from 'antd';
import 'antd/dist/reset.css';
import { Link } from 'react-router-dom';
const { Panel } = Collapse;

const { Title } = Typography
const DrawerComponent = ({ isOpen, onClose, biomarkerData }) => {
  // Sort biomarkerData alphabetically by the 'name' property
  const sortedBiomarkers = biomarkerData ? 
    [...biomarkerData].sort((a, b) => {
      const nameA = (a.name || a.biomaker || '').toLowerCase();
      const nameB = (b.name || b.biomaker || '').toLowerCase();
      return nameA.localeCompare(nameB);
    }) 
    : [];

  return (
    <Drawer
      title={null}
      onClose={onClose}
      open={isOpen}
      className="drawer"
      width={400}
      footer={null}
    >
      <Flex align='center' justify='space-between'>
        <div className='pad-title'>
          <Title level={3} className='m-0 '>
            Biomarkers
          </Title>
        </div>
        <button type='button' className='btnclose-drawer' onClick={onClose}>
          <img src="/assets/icons/close.png" width={15} alt="" />
        </button>
      </Flex>
      <div className='content-draw'>
        {
          sortedBiomarkers.length > 0 ? (
            <Collapse accordion className="custom-collapse">
              {
                sortedBiomarkers.map((biomarker, index) => (
                <Panel 
                  className='custom-panel' 
                  header={<span className=''>{biomarker.name || biomarker.biomaker}</span>}
                  key={index}
                  >
                  <p className='metrophofont'>{biomarker.description}</p>
                </Panel>
              ))}
            </Collapse>
          )
          :
          (
            <p>No details available.</p>
          )
        }
      </div>
    </Drawer>
  );
};

export default DrawerComponent;
